.w100 {
    width: 100%;
}

.m-10 {
    margin: 10px;
}

.mt-0 {
    margin-top: 0;
}

.mt-24 {
  margin-top: 24px;
}

.mt-48 {
  margin-top: 48px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-48 {
    margin-bottom: 48px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-8 {
    margin-bottom: 8px;
}

.ml-0 {
    margin-left: 0;
}

.ml-8 {
    margin-left: 8px;
}

.ml-48 {
    margin-left: 48px;
}

.mr-8 {
    margin-right: 8px;
}

.mr-16 {
    margin-right: 16px;
}

.mr-0 {
    margin-right: 0;
}

.m-0 {
    margin: 0;
}

.mb-16{
    margin-bottom: 16px;
}

.p-8 {
    padding: 8px;
}

.pt-8 {
    padding-top: 8px;
}

.p-16 {
  padding: 16px;
}

.pl-8 {
    padding-left: 8px;
}

.pr-8 {
    padding-right: 8px;
}

.pr-16 {
    padding-right: 16px;
}

.p-0 {
    padding: 0 !important;
}

.pt-32 {
    padding-top: 32px;
}


.required {
    color: red;
    margin-right: 5px;
}

.icon__size_16 {
    width: 16px;
}

.icon__size_21 {
    width: 21px;
}

.icon__size_18 {
    width: 18px;
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}